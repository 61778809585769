export const userClickEditFreshRecipe = () => ({
  type: 'Clicks edit fresh recipes',
});

export const userClickEditBakedRecipe = () => ({
  type: 'Clicks edit baked recipes',
});
export const userClickEditAddons = () => ({
  type: 'Clicks edit Extras from next box',
});
export const userClickChangeDeliveryDate = () => ({
  type: 'Clicks change delivery date',
});
export const userClicksPlusSignInNextBox = () => ({
  type: 'Clicks plus sign in next box',
});
