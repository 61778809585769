'use client';
import React from 'react';
import PropTypes from 'prop-types';
import { interpolate } from '@format/interpolate';
import { BoxShippingSoon, LoadingView } from '@web/components';
import { RedirectToLogin } from '../../router';
import { useSubscription } from '../../subscriptions';
import { useSessionCustomer } from '../../customer';
import { useProduct } from '../../products';
import { useOrder } from '../../orders';
import { AccountHeader } from '../AccountHeader';
import { useCurrentPet } from '../useCurrentPet';
import { StyledBoxShippingSoonContainer, Container } from './styles.js';

export const BoxShippingSoonContainer = ({
  content,
  nextDeliveryDate,
  links,
  logoUrl,
  logoSrText,
}) => {
  const {
    customer,
    isActive,
    isLoading: isCustomerLoading,
  } = useSessionCustomer();
  const { pet, isLoading: isPetLoading } = useCurrentPet();
  const { isLoading: isSubscriptionLoading, subscription } = useSubscription(
    pet.id,
  );
  const {
    order: currentSubscriptionOrder,
    isLoading: isCurrentSubscriptionOrderLoading,
  } = useOrder(subscription?.current_order_id);

  const { product: nextOrderProduct, isLoading: isNextProductLoading } =
    useProduct(currentSubscriptionOrder?.items[0].sku);

  const { product: subscriptionProduct, isLoading: isProductLoading } =
    useProduct(subscription?.sku);
  if (!isCustomerLoading && !isActive) {
    return <RedirectToLogin />;
  }

  if (
    isCustomerLoading ||
    isPetLoading ||
    isSubscriptionLoading ||
    isProductLoading ||
    isCurrentSubscriptionOrderLoading ||
    isNextProductLoading
  ) {
    return <LoadingView />;
  }

  const currentProductVariant = subscription.is_locked_in
    ? nextOrderProduct?.variants[0]
    : subscriptionProduct?.variants[0];

  const totalPackageMakeup = () => {
    const { tray_count, bag_count } = currentProductVariant;
    if (tray_count && bag_count) {
      return `${Number(tray_count)} packs and ${Number(bag_count)} bags`;
    } else if (tray_count) {
      return `${Number(tray_count)} packs`;
    } else if (bag_count) {
      return `${Number(bag_count)} bags`;
    }
  };

  const interpolatedContent = interpolate(content, {
    pupName: pet.name,
    totalPackageMakeup: totalPackageMakeup(),
  });

  return (
    <>
      <AccountHeader
        links={links}
        logoUrl={logoUrl}
        logoSrText={logoSrText}
        name={customer.first_name}
      />
      <StyledBoxShippingSoonContainer>
        <Container>
          <BoxShippingSoon
            {...interpolatedContent}
            dateText={nextDeliveryDate}
            petId={pet?.id}
            boxLockedIn={subscription?.is_locked_in}
          />
        </Container>
      </StyledBoxShippingSoonContainer>
    </>
  );
};

BoxShippingSoonContainer.displayName = 'BoxShippingSoonContainer';

BoxShippingSoonContainer.propTypes = {
  content: PropTypes.object.isRequired,
  nextDeliveryDate: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired,
  logoUrl: PropTypes.string.isRequired,
  logoSrText: PropTypes.string.isRequired,
};
