'use client';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, LinkButton } from '@ui/button';
import { RadioCard, SmolLink } from '@web/atoms';
import { useReporter } from '../../reporter';
import { clickLinkEvent, updateBoxFrequencyEvent } from './events.js';
import { schema } from './schema.js';
import { CardContainer, ButtonContainer, LinkContainer } from './styles';

export const FrequencyForm = ({
  choices,
  defaultValues,
  frequencyHref,
  cancelButtonText,
  submitButtonText,
  frequencyLabel,
  onSubmit,
  cancelBtnTarget,
  isSubmitting,
}) => {
  const { control, handleSubmit } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const reporter = useReporter();

  const onSubmitWithReporter = data => {
    const existingChoice = choices.find(
      choice => choice.sku === defaultValues.sku,
    );
    const newChoice = choices.find(choice => choice.sku === data.sku);
    reporter.tag(
      updateBoxFrequencyEvent(
        existingChoice.weeks_of_food,
        newChoice.weeks_of_food,
      ),
    );
    onSubmit(newChoice);
  };

  const onLinkClick = () => {
    reporter.tag(clickLinkEvent());
  };

  return (
    <form onSubmit={handleSubmit(onSubmitWithReporter)}>
      <Controller
        name="sku"
        control={control}
        render={({ field: { ref, ...field } }) => (
          <CardContainer center={choices.length < 3}>
            {choices.map(choice => (
              <RadioCard
                fontColor="hero.blueberry"
                {...field}
                title={choice.title}
                body={choice.body}
                id={choice.sku}
                key={choice.sku}
                active={field.value === choice.sku}
              />
            ))}
          </CardContainer>
        )}
      />
      <ButtonContainer>
        <Button type="submit" disabled={isSubmitting}>
          {submitButtonText}
        </Button>
        <LinkButton href={cancelBtnTarget} variant="secondary" fullWidth>
          {cancelButtonText}
        </LinkButton>
      </ButtonContainer>
      <LinkContainer onClick={onLinkClick}>
        <SmolLink href={frequencyHref}>{frequencyLabel}</SmolLink>
      </LinkContainer>
    </form>
  );
};

FrequencyForm.displayName = 'FrequencyForm';

FrequencyForm.propTypes = {
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
      weeks_of_food: PropTypes.number.isRequired,
      sku: PropTypes.string.isRequired,
    }),
  ).isRequired,
  defaultValues: PropTypes.shape({
    sku: PropTypes.string.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  cancelButtonText: PropTypes.string.isRequired,
  frequencyHref: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  frequencyLabel: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  cancelBtnTarget: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};
