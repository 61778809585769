import BeefPack from '../../images/our-food/new-beef-pack.png';
import TurkeyPack from '../../images/our-food/new-turkey-pack.png';
import ChickenPack from '../../images/our-food/new-chicken-pack.png';
import PorkPack from '../../images/our-food/pork-pack.png';
import DryChickenBag from '../../images/our-food/chicken-baked-bag.png';
import DryBeefBag from '../../images/our-food/beef-baked-bag.png';
import LambPack from '../../images/our-food/new-lamb-pack.png';
import JerkyTreatBeefSweetPotatoImage from '../../images/our-food/addons/Jerky-Beef-Sweet-Potato-Treat.jpg';
import JerkyTreatChickenAppleImage from '../../images/our-food/addons/Jerky-Chicken-Treats.jpg';
import JerkyTreatTurkeyCranImage from '../../images/our-food/addons/JerkyTreats-TurkeyCran.jpg';
import SweetPotatoTreatImage from '../../images/our-food/addons/Sweet-Potato-Slices.jpg';
import DentalChews from '../../images/our-food/addons/Dental-Chews.jpg';
import TrainingTreatsPB from '../../images/our-food/addons/Training-Treats-PB.jpg';
import TrainingTreatsParm from '../../images/our-food/addons/Training-Treats-Parm.jpg';
import SkinSupplement from '../../images/our-food/addons/Skin-Supplement.jpg';
import ImmuneSupplement from '../../images/our-food/addons/Immunity-Supplement.jpg';
import SweetPotatoCrunch from '../../images/our-food/addons/Sweet-Potato-Crunch.jpg';
import AppleCrunch from '../../images/our-food/addons/Apple-Crunch-Treat.jpg';
import ZoomiesImage from '../../images/our-food/addons/Hip-Joint-Supplement.jpg';
import ChillPillsImage from '../../images/our-food/addons/Calming-Chews.jpg';
import BellyRubsImage from '../../images/our-food/addons/Probiotic-Chews.jpg';
import PBIceCreamImage from '../../images/our-food/addons/Ice-Cream-PB-Banana.jpg';
import { ADDON_SKUS } from '../constants';

export const recipeImageMap = {
  fresh: {
    beef: BeefPack,
    chicken: ChickenPack,
    turkey: TurkeyPack,
    lamb: LambPack,
    pork: PorkPack,
  },
  dry: {
    beef: DryBeefBag,
    chicken: DryChickenBag,
  },
};

export const ALL_EXTRAS_EVERY_OTHER =
  'Your Extras are set to ‘Every other box’ and will arrive in the box after this delivery.';
export const SOME_EXTRAS_NEXT_ORDER =
  "Some of your Extras are set to 'Every other box'. Review your Extras subscription below for more info.";
export const ALL_EXTRAS_NEXT_ORDER =
  'Your Extras will arrive in the box after this delivery.';

export const VIEW_TRACKING_INFORMATION = 'Track your box';

export const addonImageMap = {
  [ADDON_SKUS?.zoomies]: ZoomiesImage,
  [ADDON_SKUS?.bellyrubs]: BellyRubsImage,
  [ADDON_SKUS?.chillpills]: ChillPillsImage,
  [ADDON_SKUS?.beefjerky]: JerkyTreatBeefSweetPotatoImage,
  [ADDON_SKUS?.turkeyjerky]: JerkyTreatTurkeyCranImage,
  [ADDON_SKUS?.chickenjerky]: JerkyTreatChickenAppleImage,
  [ADDON_SKUS?.sweetpotatoes]: SweetPotatoTreatImage,
  [ADDON_SKUS?.dentalchewxs]: DentalChews,
  [ADDON_SKUS?.dentalchewsm]: DentalChews,
  [ADDON_SKUS?.dentalchewmd]: DentalChews,
  [ADDON_SKUS?.dentalchewlg]: DentalChews,
  [ADDON_SKUS?.trainingtreatspb]: TrainingTreatsPB,
  [ADDON_SKUS?.trainingtreatsparm]: TrainingTreatsParm,
  [ADDON_SKUS?.sweetpotatocrunch]: SweetPotatoCrunch,
  [ADDON_SKUS?.applecrunchtreat]: AppleCrunch,
  [ADDON_SKUS?.skinsupplement]: SkinSupplement,
  [ADDON_SKUS?.immunesupplement]: ImmuneSupplement,
  [ADDON_SKUS?.pbicecream]: PBIceCreamImage,
};

export const addons = [
  {
    sku: ADDON_SKUS.zoomies,
    title: 'Zoomies',
    product_type: 'SP',
    subTitle: 'Soft Chew Treats',
    size: '1 bag (5oz.)',
    pricingInfo: { price: '$37.50', discount: '$25.00' },
    quantity: 2,
    frequency: 1,
  },
  {
    sku: ADDON_SKUS.beefjerky,
    title: 'Beef jerky',
    product_type: 'TR',
    subTitle: 'Soft Chew Treats',
    size: '1 bag (5oz.)',
    pricingInfo: { price: '$37.50', discount: '$25.00' },
    quantity: 1,
    frequency: 1,
  },
  {
    sku: ADDON_SKUS.bellyrubs,
    title: 'Belly Rubs',
    product_type: 'SP',
    subTitle: 'Soft Chew Treats',
    size: '1 bag (5oz.)',
    pricingInfo: { price: '$37.50', discount: '$25.00' },
    quantity: 2,
    frequency: 2,
  },
  {
    sku: ADDON_SKUS.turkeyjerky,
    title: 'Turkey Jerky',
    product_type: 'TR',
    subTitle: 'Soft Chew Treats',
    size: '1 bag (5oz.)',
    pricingInfo: { price: '$37.50', discount: '$25.00' },
    quantity: 3,
    frequency: 2,
  },
];

export const requiredProps = {
  headline: "Scooby's next box",
  arrivalDate: 'Apr 29',
  onOrderDetailsClick: () => {},
  cadence: 4,
  recipes: { fresh: ['beef', 'chicken', 'pork'], dry: ['beef'] },
  latestOrderAddons: addons,
  subscriptionAddons: addons,
  totalPacks: 42,
  totalBags: 2,
  editFreshHref: '/account/edit-recipes/recipes/fresh',
  editBakedHref: '/account/edit-recipes/recipes/baked',
  editAddonsHref: '/account/edit-recipes/extras',
  editDeliveryDateHref: '/account/edit-delivery-date/quick',
  editDeliveryDateText: 'Change delivery date',
  isLockedIn: false,
  showAddons: true,
};
