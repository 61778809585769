import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Heading } from '@web/atoms';
import { useReporter } from '../../reporter';
import { InfoLink } from '../InfoLink';
import { RecipeNavLink } from '../RecipeNavLink';
import { AddonsNavLinkContainer } from '../AddonsNavLinkContainer';
import {
  userSelectsDashboardMealPrep,
  userClicksOnFoodPortionSection,
} from './events';
import { InfoContainer } from './styles.js';
import { AppCTAModal } from './components';

export const BoxManagementNav = ({
  addons,
  showAddons,
  label,
  petId,
  shippingDate,
  frequency,
  shippingInfo,
  paymentInfo,
  product,
  mealPrep,
  showPortionSection,
  portionSectionText,
  portionSectionModalContent,
}) => {
  const reporter = useReporter();
  const [showPortionSectionModal, setShowPortionSectionModal] = useState(false);

  const onPortionSectionClick = () => {
    reporter.tag(userClicksOnFoodPortionSection());
    setShowPortionSectionModal(!showPortionSectionModal);
  };
  return (
    <>
      <Heading
        headingLevel="h2"
        typography={{ desktop: 'heading2', mobile: 'heading1' }}
        headingText={label}
      />
      <InfoContainer>
        <InfoLink
          title="Delivery date"
          href={{
            pathname: '/account/edit-delivery-date/quick',
            query: { pet_id: petId },
          }}
          infoList={shippingDate}
        />
        <InfoLink
          title="Box frequency"
          href={{
            pathname: '/account/alter-frequency',
            query: { pet_id: petId },
          }}
          infoList={frequency}
        />
        <InfoLink
          title="Meal plan"
          onInfoClick={() => reporter.tag(userSelectsDashboardMealPrep())}
          href={{
            pathname: '/account/edit-recipes/mealprep',
            query: { pet_id: petId },
          }}
          infoList={mealPrep}
        />
        {showPortionSection && (
          <>
            <InfoLink
              title="Food Portion"
              onInfoClick={onPortionSectionClick}
              infoList={portionSectionText}
            />
            <AppCTAModal
              shown={showPortionSectionModal}
              onCloseModal={() => setShowPortionSectionModal(false)}
              content={portionSectionModalContent}
            />
          </>
        )}
        <RecipeNavLink
          title="Recipes"
          mealPrep={mealPrep}
          freshHref={{
            pathname: '/account/edit-recipes/recipes/fresh/',
            query: {
              pet_id: petId,
              meal_prep: product.variable,
            },
          }}
          bakedHref={{
            pathname: '/account/edit-recipes/recipes/baked/',
            query: { pet_id: petId, meal_prep: product.variable },
          }}
          recipes={product.recipes}
        />
        {showAddons && (
          <AddonsNavLinkContainer
            title={'Extras'}
            addons={addons}
            onInfoClick={() => null}
            navLinks={[
              {
                addonType: 'treats',
                href: {
                  pathname: '/account/edit-recipes/extras',
                  query: { pet_id: petId, direct_edit: true },
                },
              },
              {
                addonType: 'supplements',
                href: {
                  pathname: '/account/edit-recipes/extras',
                  query: { pet_id: petId, direct_edit: true },
                },
              },
            ]}
          />
        )}
        <InfoLink
          title="Shipping address"
          href="/account/edit-shipping-address"
          infoList={shippingInfo}
        />
        <InfoLink
          title={'Payment method'}
          href="/account/edit-payment"
          infoList={[paymentInfo]}
        />
      </InfoContainer>
    </>
  );
};

BoxManagementNav.propTypes = {
  showAddons: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  petId: PropTypes.string.isRequired,
  shippingDate: PropTypes.arrayOf(PropTypes.string).isRequired,
  frequency: PropTypes.arrayOf(PropTypes.string).isRequired,
  shippingInfo: PropTypes.arrayOf(PropTypes.string).isRequired,
  paymentInfo: PropTypes.string.isRequired,
  mealPrep: PropTypes.arrayOf(PropTypes.string).isRequired,
  product: PropTypes.shape({
    variable: PropTypes.string,
    recipes: PropTypes.shape({
      fresh: PropTypes.arrayOf(PropTypes.string),
      dry: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  addons: PropTypes.arrayOf(
    PropTypes.shape({
      product_name: PropTypes.string.isRequired,
      product_type: PropTypes.string.isRequired,
    }),
  ),
  showPortionSection: PropTypes.bool.isRequired,
  portionSectionText: PropTypes.arrayOf(PropTypes.string).isRequired,
  portionSectionModalContent: PropTypes.object,
};
