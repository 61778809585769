'use client';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { addWeeks, differenceInDays, format, parseISO } from 'date-fns';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { Footer, LoadingView } from '@web/components';
import { Heading } from '@web/atoms';
import { useReporter } from '../../reporter';
import { useDeliveryDates, useSubscription } from '../../subscriptions';
import { useCurrentPet } from '../useCurrentPet';
import { RedirectToLogin, Redirect } from '../../router';
import { QuickSelectForm } from '../QuickSelectForm';
import { FOOTER_CONTENT } from '../../testing/constants';
import { EditDeliveryProtectedRoute } from '../EditDeliveryProtectedRoute';
import { ApiResult } from '../../api';
import { ERROR_MESSAGE, QUICK } from '../constants';
import { useToast, TOAST_CLIENT_ERROR_MESSAGE } from '../../Toast';
import { useSessionCustomer } from '../../customer';
import { AccountHeader } from '../AccountHeader';
import { userUpdatesDeliveryDate } from '../DatePickerContainer/events';
import warningInfoIcon from '../../images/Icons/warning-info-icon.svg';
import { findNextAvailableDate, approximateSkippedDelivery } from './functions';
import { FormWrapper, WarningText } from './styles.js';
import { viewChangeDeliveryDateQuickSelect } from './events';
import {
  SKIP_NEXT_BOX_ID,
  AS_SOON_AS_POSSIBLE_ID,
  SKIP_BOX_ERROR_MESSAGE,
} from './constants';

export const QuickSelectContainer = ({
  title,
  differentDateLabel,
  differentDateHref,
  submitButtonText,
  cancelButtonText,
  cancelButtonVariant,
  frequencyHref,
  frequencyLabel,
  shippingBoxSoon,
  links,
  logoUrl,
  logoSrText,
  asapWarning,
}) => {
  const reporter = useReporter();
  const { showToast } = useToast();
  const router = useRouter();
  const { pet, isLoading: petIsLoading } = useCurrentPet();
  const {
    customer,
    isActive,
    isLoading: isCustomerLoading,
  } = useSessionCustomer();
  const {
    skipNextDelivery,
    subscription,
    isLoading: isLoadingSubscription,
    updateSubscription,
  } = useSubscription(pet?.id);
  const { deliveryDates, isLoading: isLoadingDeliveryDates } = useDeliveryDates(
    subscription?.id,
  );
  const nextDeliveryDate = parseISO(
    subscription?.next_delivery_target || '0000-00-00',
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    reporter.tag(viewChangeDeliveryDateQuickSelect());
  }, [reporter]);

  if (!isCustomerLoading && !isActive) {
    return <RedirectToLogin />;
  }

  if (
    isLoadingDeliveryDates ||
    isLoadingSubscription ||
    isCustomerLoading ||
    petIsLoading
  ) {
    return <LoadingView />;
  }
  const nextDeliveryDateFormatted = format(
    nextDeliveryDate,
    'EEEE, MMMM do, yyyy',
  );
  const nextDeliveryLabel = `Next delivery for ${pet.name}`;

  const asapFormatted = format(parseISO(deliveryDates[0]), 'MMMM d');
  const oneWeekLater = findNextAvailableDate(
    deliveryDates,
    addWeeks(nextDeliveryDate, 1),
  );
  const twoWeeksLater = findNextAvailableDate(
    deliveryDates,
    addWeeks(nextDeliveryDate, 2),
  );
  const nextDelivery = addWeeks(nextDeliveryDate, subscription.frequency);
  const nextAvailableDelivery = findNextAvailableDate(
    deliveryDates,
    nextDelivery,
  );

  const getCardMessage = time =>
    time ? `Arrives on ${format(time, 'MMMM d')}` : null;

  const filterChoices = () => {
    const asapMessage = `Arrives on ${asapFormatted}`;
    const oneWeekMessage = getCardMessage(oneWeekLater);
    const twoWeekMessage = getCardMessage(twoWeeksLater);
    const nextDeliveryMessage = nextAvailableDelivery
      ? `Your next box would ship ${format(nextAvailableDelivery, 'MMMM d')}`
      : `Your deliveries will resume in ${approximateSkippedDelivery(
          nextDeliveryDate,
          subscription.frequency,
        )} weeks`;

    const quickSelectDates = [
      {
        title: 'As soon as possible',
        body: (
          <>
            {asapMessage}
            <WarningText>
              <Image alt="Warning info icon" src={warningInfoIcon} />
              {asapWarning}
            </WarningText>
          </>
        ),
        date: deliveryDates[0],
        id: '1',
      },
      {
        title: '1 Week Later',
        body: oneWeekMessage,
        date: oneWeekLater ? format(oneWeekLater, 'yyyy-MM-dd') : null,
        id: '2',
      },
      {
        title: '2 Weeks Later',
        body: twoWeekMessage,
        date: twoWeeksLater ? format(twoWeeksLater, 'yyyy-MM-dd') : null,
        id: '3',
      },
      {
        title: 'Skip this box',
        body: nextDeliveryMessage,
        id: SKIP_NEXT_BOX_ID,
      },
    ];

    return quickSelectDates.filter(date => date.body !== null);
  };

  const quickSelectCards = filterChoices();

  const getDaysChanged = newNextDeliveryDate =>
    differenceInDays(parseISO(newNextDeliveryDate), nextDeliveryDate);

  const onSubmit = async ({ shippingDate: shippingDateId }) => {
    setIsSubmitting(true);
    const nextDate = quickSelectCards.find(card => card.id === shippingDateId);
    const skippedNextBox = shippingDateId === SKIP_NEXT_BOX_ID;
    const result = skippedNextBox
      ? await skipNextDelivery()
      : await updateSubscription({
          next_delivery_target: nextDate.date,
        });
    ApiResult.match(result, {
      success: () => {
        reporter.tag(
          userUpdatesDeliveryDate({
            page: QUICK,
            days_changed: skippedNextBox ? null : getDaysChanged(nextDate.date),
            skipped: skippedNextBox,
            asap: shippingDateId === AS_SOON_AS_POSSIBLE_ID,
            original_delivery_date: format(nextDeliveryDate, 'yyyy-MM-dd'),
            updated_delivery_date: skippedNextBox ? '' : nextDate.date,
          }),
        );
        router.push('/account');
        showToast({
          status: 'success',
          message: 'Your box shipping date has been successfully updated.',
          headline: 'Success',
        });
      },
      error: {
        server: () =>
          showToast({
            status: 'error',
            message: ERROR_MESSAGE,
            headline: 'Error',
          }),
        client: error => {
          reporter.error(error.original);
          showToast({
            status: 'error',
            message: TOAST_CLIENT_ERROR_MESSAGE,
            headline: 'Error',
          });
        },
        skipNextDeliveryErr: () => {
          showToast({
            status: 'error',
            message: SKIP_BOX_ERROR_MESSAGE,
            headline: 'Error',
          });
        },
      },
    });
    ApiResult.ifError(result, () => setTimeout(() => setIsSubmitting(false)));
  };

  if (quickSelectCards.length <= 1) {
    return <Redirect replace to={`/account/edit-delivery-date/full`} />;
  }
  return (
    <EditDeliveryProtectedRoute
      shippingBoxSoon={shippingBoxSoon}
      links={links}
      logoUrl={logoUrl}
      logoSrText={logoSrText}
    >
      <AccountHeader
        links={links}
        logoUrl={logoUrl}
        logoSrText={logoSrText}
        name={customer.first_name}
      />
      <FormWrapper>
        <Heading
          headingLevel="h1"
          typography="heading1"
          headingText={title}
          position="center"
        />
        <QuickSelectForm
          onSubmit={onSubmit}
          choices={quickSelectCards}
          differentDateLabel={differentDateLabel}
          differentDateHref={{
            pathname: differentDateHref,
            query: { pet_id: pet.id },
          }}
          submitButtonText={submitButtonText}
          cancelButtonText={cancelButtonText}
          cancelButtonVariant={cancelButtonVariant}
          nextDeliveryLabel={nextDeliveryLabel}
          nextDeliveryDate={nextDeliveryDateFormatted}
          frequencyHref={{ pathname: frequencyHref, query: { pet_id: pet.id } }}
          frequencyLabel={frequencyLabel}
          cancelHref={{ pathname: '/account', query: { pet_id: pet.id } }}
          isSubmitting={isSubmitting}
        />
      </FormWrapper>
      <Footer {...FOOTER_CONTENT} />
    </EditDeliveryProtectedRoute>
  );
};

QuickSelectContainer.displayName = 'QuickSelectContainer';

QuickSelectContainer.propTypes = {
  title: PropTypes.string.isRequired,
  differentDateLabel: PropTypes.string.isRequired,
  differentDateHref: PropTypes.string.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  cancelButtonText: PropTypes.string.isRequired,
  cancelButtonVariant: PropTypes.string.isRequired,
  frequencyHref: PropTypes.string.isRequired,
  frequencyLabel: PropTypes.string.isRequired,
  shippingBoxSoon: PropTypes.object.isRequired,
  links: PropTypes.array.isRequired,
  logoUrl: PropTypes.string.isRequired,
  logoSrText: PropTypes.string.isRequired,
  asapWarning: PropTypes.string.isRequired,
};
