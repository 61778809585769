export const quickSelectInfo = {
  title: 'Change box delivery date',
  differentDateLabel: 'Select a different date',
  differentDateHref: '/account/edit-delivery-date/full',
  submitButtonText: 'Save changes',
  cancelButtonText: 'Cancel',
  cancelButtonVariant: 'secondary',
  frequencyHref: '/account/alter-frequency',
  frequencyLabel: 'Change your box frequency',
  asapWarning:
    'You may no longer be able to make changes to this box after saving this option.',
};

export const SKIP_NEXT_BOX_ID = '4';
export const AS_SOON_AS_POSSIBLE_ID = '1';
export const SKIP_BOX_ERROR_MESSAGE =
  'Failed to skip delivery: new date would be >90 days in the future';
