export const userUpdatesDeliveryDate = ({
  page,
  days_changed,
  skipped,
  asap,
  original_delivery_date,
  updated_delivery_date,
}) => ({
  type: `User updates delivery date`,
  data: {
    page,
    days_changed,
    skipped,
    asap,
    original_delivery_date,
    updated_delivery_date,
  },
});

export const viewChangeDeliveryDateCalendar = () => ({
  type: `View Change Delivery Date calendar page`,
});

export const changeDeliveryDateInitiated = () => ({
  type: `View Change Delivery Date quick select page`,
});

export const changeDeliveryDateAction = () => ({
  type: `User updates delivery date`,
});
