import React from 'react';
import PropTypes from 'prop-types';
import { CardWrapper, Label, Body, Info, Input } from './styles';

export const RadioCard = ({
  id,
  title,
  fontColor = 'text.secondary',
  body,
  onChange,
  active,
  ariaControls,
}) => {
  return (
    <CardWrapper
      htmlFor={id}
      active={active}
      role="tab"
      aria-selected={active}
      aria-controls={ariaControls}
      aria-expanded={active}
    >
      <Info>
        <Input
          type="radio"
          name={title}
          id={id}
          value={title}
          checked={active}
          onChange={() => onChange(id)}
        />
        <Label htmlFor={id}>{title}</Label>
        <Body $fontColor={fontColor}>{body}</Body>
      </Info>
    </CardWrapper>
  );
};

RadioCard.displayName = 'RadioCard';

RadioCard.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  fontColor: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  active: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  ariaControls: PropTypes.string, // new prop to link to the content area
};
