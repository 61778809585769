'use client';
import styled, { css } from 'styled-components';

export const CardContainer = styled.div`
  display: grid;
  grid-gap: ${props => props.theme.spacing(3)};

  @media ${props => props.theme.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  /* TODO: Leave the centering as a default so we don't have to pass any prop. We'll probably
  need to stop using grid whatsoever */
  ${props =>
    props.center &&
    css`
      @media ${props => props.theme.tablet} {
        display: flex;
        justify-content: center;
        gap: ${props => props.theme.spacing(4)};

        & > div {
          flex: 0 0 336px;
        }
      }
    `}
`;

export const ButtonContainer = styled.div`
  margin: auto;
  display: grid;
  text-align: center;
  gap: ${props => props.theme.spacing(4)};
  padding: ${props => props.theme.spacing(12, 0)};
  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(14, 0)};
    max-width: 512px;
  }
`;

export const LinkContainer = styled.div`
  text-align: center;
`;
