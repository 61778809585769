'use client';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { LinkButton } from '@ui/button';
import { Heading, Divider } from '@web/atoms';
import { ResponsiveImage } from '@web/molecules';
import { useReporter } from '../../reporter';
import {
  boxShippingSoonBackDashboard,
  boxShippingSoonInitiated,
} from './events';
import {
  StyledBoxShippingSoon,
  WarningText,
  DateText,
  DateContainer,
  InfoText,
  ImageContainer,
  NavLinksContainer,
  DashboardLink,
} from './styles.js';

export const BoxShippingSoon = ({
  headline,
  warningText,
  infoText,
  image,
  dateText,
  dashboardLinkLabel,
  dashboardUrl,
  datePickerUrl,
  petId,
  updateNextBoxQuestionText,
  updateNextBoxDeliveryButtonLabel,
  boxLockedIn,
}) => {
  const reporter = useReporter();
  useEffect(() => {
    reporter.tag(boxShippingSoonInitiated());
  }, [reporter]);
  const onBoxShippingSoonBackDashboardClick = () => {
    reporter.tag(boxShippingSoonBackDashboard());
  };

  const datePickerHref = {
    pathname: datePickerUrl,
    query: { pet_id: petId },
  };
  return (
    <StyledBoxShippingSoon>
      <ImageContainer>
        <ResponsiveImage {...image} />
      </ImageContainer>
      <Heading
        headingLevel="h2"
        typography={{
          desktop: 'heading1',
          mobile: 'heading2',
        }}
        position="center"
        headingText={headline}
        backgroundColor="background.lightest"
      />
      <DateContainer>
        <InfoText>{infoText}</InfoText>
        <DateText>{dateText}</DateText>
      </DateContainer>
      <WarningText>{warningText}</WarningText>
      {boxLockedIn && <Divider direction="horizontal" />}
      <NavLinksContainer>
        {boxLockedIn && (
          <NavLinksContainer>
            <p>{updateNextBoxQuestionText}</p>
            <LinkButton href={datePickerHref} minWidth={320}>
              {updateNextBoxDeliveryButtonLabel}
            </LinkButton>
          </NavLinksContainer>
        )}
        <DashboardLink
          href={dashboardUrl}
          onClick={onBoxShippingSoonBackDashboardClick}
        >
          {dashboardLinkLabel}
        </DashboardLink>
      </NavLinksContainer>
    </StyledBoxShippingSoon>
  );
};

BoxShippingSoon.displayName = 'BoxShippingSoon';

BoxShippingSoon.propTypes = {
  headline: PropTypes.string.isRequired,
  warningText: PropTypes.string.isRequired,
  infoText: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  dateText: PropTypes.string.isRequired,
  dashboardLinkLabel: PropTypes.string.isRequired,
  dashboardUrl: PropTypes.string.isRequired,
  datePickerUrl: PropTypes.string.isRequired,
  petId: PropTypes.string.isRequired,
  updateNextBoxQuestionText: PropTypes.string.isRequired,
  updateNextBoxDeliveryButtonLabel: PropTypes.string.isRequired,
  boxLockedIn: PropTypes.bool.isRequired,
};
