'use client';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { interpolate } from '@format/interpolate';
import { costFormatter } from '@format/currency';
import { LoadingView } from '@web/components';
import { useCampaign } from '../../campaign';
import { usePayment } from '../../payment';
import { ApiResult } from '../../api';
import { RedirectToLogin } from '../../router';
import { shared } from '../../config';
import { useToast, TOAST_CLIENT_ERROR_MESSAGE } from '../../Toast';
import { useProduct } from '../../products';
import { PlanSummaryExtras } from '../PlanSummaryExtras';
import {
  PRODUCT_TYPE_FRESH,
  PRODUCT_TYPE_HALF_FRESH,
  HALF_PORTION,
} from '../../products/constants';
import { getPetInfo } from '../AccountContainer/dataParsers';
import { useReporter } from '../../reporter';
import {
  PAYMENT_ERROR_MESSAGE,
  ADDON_PRODUCT_TYPES,
  MEAL_PLAN_TYPES,
} from '../constants';
import { REACTIVATION_CODE_TYPE } from '../../offer';
import { planReactivateClicked, planSummaryReactivation } from './events';

export const ReactivationPlanSummaryContainer = ({
  cancelBtnLabel,
  headline,
  productName,
  subHeadingText,
  confirmationText,
  pupInfoTarget,
  mealPrepTarget,
  paymentTarget,
  deliveryTarget,
  cancelTarget,
  onSuccess,
  detailsSectionTitle,
  addonsTarget,
  addonsTitle,
  subsIsLoading,
  subscription,
  reactivatePlan,
  subscriptionAddonProducts,
  subscriptionAddonProductsLoading,
  pet,
  petIsLoading,
  isActive,
  isCustomerLoading,
  customer,
  reactivationCart,
  reactivationCartLoading,
  isReactivationCartValidating,
  refreshReactivationCart,
  offer,
  invalidQueryCodes,
  address,
  isAddressLoading,
}) => {
  const router = useRouter();
  const reporter = useReporter();
  const petId = router.query.pet_id;
  const { showToast } = useToast();
  const { payment, isLoading: isPaymentLoading } = usePayment(customer?.id);
  const { product, isLoading: isProductLoading } = useProduct(
    subscription?.sku,
    !!offer.category && offer.category === REACTIVATION_CODE_TYPE
      ? { couponCode: offer.code }
      : !invalidQueryCodes.includes(shared.DEFAULT_REACTIVATION_OFFER_CODE)
      ? { couponCode: shared.DEFAULT_REACTIVATION_OFFER_CODE }
      : { couponCode: null },
  );
  const { campaignId, setCampaignId } = useCampaign();

  const [errorMessage, setErrorMessage] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [offerCodeIsValid, setOfferCodeIsValid] = useState(true);
  const isLoading =
    petIsLoading ||
    isCustomerLoading ||
    subsIsLoading ||
    isProductLoading ||
    isAddressLoading ||
    reactivationCartLoading ||
    isPaymentLoading ||
    subscriptionAddonProductsLoading ||
    isReactivationCartValidating;

  const getMealPrep = ({ product_type, portion_size }) => {
    if (product_type === PRODUCT_TYPE_FRESH && portion_size === HALF_PORTION) {
      return PRODUCT_TYPE_HALF_FRESH;
    }
    return MEAL_PLAN_TYPES[product_type];
  };

  let paymentInfo = [];

  useEffect(() => {
    if (!isLoading) reporter.tag(planSummaryReactivation());
    if (router.isReady) {
      if (!router.query.offer_code) {
        const offer_code =
          !!offer.category && offer.category === REACTIVATION_CODE_TYPE
            ? offer.code
            : shared.DEFAULT_REACTIVATION_OFFER_CODE;
        router.replace({
          pathname: router.pathname,
          query: { ...router.query, offer_code },
        });
      } else if (
        !!router.query.offer_code &&
        invalidQueryCodes.includes(router.query.offer_code) &&
        router.query.offer_code !== shared.DEFAULT_REACTIVATION_OFFER_CODE
      ) {
        router.replace({
          pathname: router.pathname,
          query: {
            ...router.query,
            offer_code: shared.DEFAULT_REACTIVATION_OFFER_CODE,
          },
        });
      }
      //if the code in the query param is invalid the query param will update to the default.
      //if the default is invalid set state to false
      if (
        !!router.query.offer_code &&
        invalidQueryCodes.includes(router.query.offer_code)
      ) {
        setOfferCodeIsValid(false);
      } else {
        setOfferCodeIsValid(true);
      }
    }
  }, [reporter, isLoading, invalidQueryCodes, offer, router]);

  const reactivationAddons = reactivationCart?.line_items.filter(item =>
    Object.values(ADDON_PRODUCT_TYPES).includes(item.product_type),
  );
  const subscriptionAddonsWithProductType = subscriptionAddonProducts?.filter(
    addon => Object.values(ADDON_PRODUCT_TYPES).includes(addon.product_type),
  );
  useEffect(() => {
    if (!!reactivationAddons) {
      subscriptionAddonsWithProductType.length !== reactivationAddons?.length &&
        refreshReactivationCart();
    }
  }, [
    subscriptionAddonsWithProductType,
    reactivationAddons,
    refreshReactivationCart,
  ]);

  if (!isCustomerLoading && !isActive) {
    return <RedirectToLogin />;
  }

  if (isLoading) {
    return <LoadingView />;
  }

  const freshRecipesTarget = `/account/reactivate-pup/plan/recipes/fresh/?pet_id=${petId}&meal_prep=${getMealPrep(
    product,
  )}`;
  const bakedRecipesTarget = `/account/reactivate-pup/plan/recipes/baked/?pet_id=${petId}&meal_prep=${getMealPrep(
    product,
  )}`;

  const availableCart = !!reactivationCart;

  const reactivationCartAddonList =
    subscriptionAddonProducts.length > 0
      ? reactivationAddons?.map(addonItem => {
          const {
            sku,
            discounted_price,
            price,
            quantity,
            product_type,
            product_name,
          } = addonItem;

          return {
            sku,
            discounted_price,
            price,
            quantity,
            product_type,
            product_name,
          };
        }) ?? []
      : [];

  const subscriptionMealPlanCartItemPrice = reactivationCart?.line_items
    .filter(item => Object.keys(MEAL_PLAN_TYPES).includes(item.product_type))
    .map(mealPlanItem => {
      const { discounted_price, price } = mealPlanItem;
      return { discounted_price, price };
    })[0] ?? { discounted_price: '0', price: '0' };

  const pupInfo = [`${pet.name} is a ${getPetInfo(pet)}`];
  const recipeInfo = product.variants[0].recipes;

  const portionSize = product.portion_size;

  if (payment && Object.values(payment)?.length) {
    paymentInfo = [
      `${payment.brand}`,
      `Ending in **** ${payment.last_four_digits}`,
    ];
  } else {
    paymentInfo = [];
  }

  const deliveryInfo = [
    `${address.first_name} ${address.last_name}`,
    `${address.address1}, ${address.address2}`,
    `${address.city}, ${address.state} ${address.zip_code}`,
  ];

  const onSubmit = async () => {
    setIsSubmitting(true);
    const code =
      !!offer.category && offer.category === REACTIVATION_CODE_TYPE
        ? offer.code
        : !invalidQueryCodes.includes(shared.DEFAULT_REACTIVATION_OFFER_CODE)
        ? shared.DEFAULT_REACTIVATION_OFFER_CODE
        : null;

    const result = await reactivatePlan(code, campaignId);
    reporter.tag(planReactivateClicked());
    ApiResult.match(result, {
      success: () => {
        onSuccess();
        if (campaignId) setCampaignId(null);
      },
      error: {
        server: error => {
          showToast({
            status: 'error',
            message:
              'We weren’t able to complete your request, please refresh the page and try again.',
            headline: 'Error Message',
          });
        },
        client: error => {
          reporter.error(error.original);
          showToast({
            status: 'error',
            message: TOAST_CLIENT_ERROR_MESSAGE,
            headline: 'Error Message',
          });
        },
        paymentInvalid: () => setErrorMessage(PAYMENT_ERROR_MESSAGE),
      },
    });
    ApiResult.ifError(result, () => setTimeout(() => setIsSubmitting(false)));
  };
  const totalBeforeDiscount = costFormatter(
    parseFloat(reactivationCart?.subtotal ?? '0') +
      parseFloat(reactivationCart?.total_tax ?? '0'),
  );
  return (
    <PlanSummaryExtras
      confirmationButtonText="Reactivate subscription"
      name={customer.first_name}
      headline={interpolate(headline, { pupName: pet.name })}
      subheadingText={subHeadingText}
      pupsInfo={pupInfo}
      recipesInfo={recipeInfo}
      paymentInfo={paymentInfo}
      paymentMethodIsActive={payment?.is_active}
      deliveryInfo={deliveryInfo}
      confirmationText={confirmationText}
      cancelButtonLabel={cancelBtnLabel}
      boxheadline={interpolate(productName, {
        pupName: pet.name,
        frequency: product.weeks_of_food,
      })}
      addonsTitle={addonsTitle}
      addonsTarget={addonsTarget}
      reactivationCartAddonList={reactivationCartAddonList}
      totalDiscountAmount={reactivationCart?.total_discount ?? '0'}
      totalBeforeDiscount={totalBeforeDiscount}
      totalTax={reactivationCart?.total_tax}
      totalAmount={reactivationCart?.total}
      offerCategory={REACTIVATION_CODE_TYPE}
      percentDiscount={offer?.amount ?? '50'}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      mealPlanPriceInfo={subscriptionMealPlanCartItemPrice}
      reactivationMode
      pupInfoTarget={pupInfoTarget}
      freshRecipesTarget={freshRecipesTarget}
      dryRecipesTarget={bakedRecipesTarget}
      mealPrepTarget={mealPrepTarget}
      paymentTarget={paymentTarget}
      deliveryTarget={deliveryTarget}
      errorMessage={errorMessage}
      cancelButtonTarget={cancelTarget}
      portionSize={portionSize}
      frequency={subscription.frequency}
      availableReactivationCart={availableCart}
      detailsSectionTitle={detailsSectionTitle}
      offerCodeIsValid={offerCodeIsValid}
    />
  );
};

ReactivationPlanSummaryContainer.displayName =
  'ReactivationPlanSummaryContainer';

ReactivationPlanSummaryContainer.propTypes = {
  headline: PropTypes.string.isRequired,
  subHeadingText: PropTypes.string.isRequired,
  confirmationText: PropTypes.string.isRequired,
  cancelBtnLabel: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  cancelTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  pupInfoTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  mealPrepTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  paymentTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  deliveryTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  onSuccess: PropTypes.func.isRequired,
  detailsSectionTitle: PropTypes.string.isRequired,
  addonsTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  addonsTitle: PropTypes.string.isRequired,
};
